@font-face {
  font-family: "ms_sans_serif";
  src: url("react95/dist/fonts/ms_sans_serif.woff2") format("woff2");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "ms_sans_serif";
  src: url("react95/dist/fonts/ms_sans_serif_bold.woff2") format("woff2");
  font-weight: bold;
  font-style: normal;
}

body {
  font-family: "ms_sans_serif";
  background: RGB(1, 130, 129);
}
